
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, toastController } from '@ionic/vue';
import axios from "axios";

export default {
  name: 'CreateInserat',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton
  },
  data () {
    return {
      finishedLoading: false
    }
  },
  mounted() {

    // reset inserat local storage
    localStorage.inseratStep = 0;
    localStorage.inseratType = null;
    localStorage.inseratTitle = null;
    localStorage.inseratDescription = null;
    localStorage.inseratCategoryId = null;
    localStorage.inseratPrice = null;
    localStorage.inseratCurrency = null;
    localStorage.inseratIsGoodCause = null;
    localStorage.inseratDonationRecipient = null;
    localStorage.inseratDonationRecipientId = null;
    localStorage.persistedInserat = null;
    localStorage.persistedImage = null;


    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){

      // check for user permissions
      axios.get(process.env.VUE_APP_API_URL + '/star-permissions', {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {

            if (!response.data.status){
              this.$router.push({ path: '/login' })
            }

            this.finishedLoading = true;

          })
          .catch(error => {
            this.$router.push({ path: '/login' });
          });

    } else {
        // push to main
        this.$router.push({ path: '/login' })
    }

  },
  methods: {
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration
          })
      return toast.present();
    },
    nextStep(selection: number) {

        if (selection == 0){
            // show error
            this.openToast("Ungültige Auswahl.", 1000);
        } else if (selection >= 4){
            // show error
            this.openToast("Ungültige Auswahl.", 1000);
        } else {
            // save in localStorage

            if (selection == 1){
              localStorage.inseratType = "directbuy";
            }

            if (selection == 2){
              localStorage.inseratType = "auction";
            }

            if (selection == 3){
              localStorage.inseratType = "gift";
            }

            // go to next step
            localStorage.inseratStep = 1;
            this.$router.push({ path: '/inserat/create/step-1' })
        }
    }
  }
}
