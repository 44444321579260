<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Inserat erstellen</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="finishedLoading">
      <div id="container">
        <strong class="capitalize">Inserat Erstellen</strong>
        <p>Wähle einen Inserat Typ</p>

        <ion-button size="large" class="topMargins sideMargins" @click="nextStep(1)" expand="block" color="primary">Sofortkaufen</ion-button>
        <ion-button size="large" class="halfMargins sideMargins" @click="nextStep(2)" expand="block" color="secondary">Auktion</ion-button>
        <ion-button size="large" class="halfMargins sideMargins" @click="nextStep(3)" expand="block" color="danger">Geschenk</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, toastController } from '@ionic/vue';
import axios from "axios";

export default {
  name: 'CreateInserat',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton
  },
  data () {
    return {
      finishedLoading: false
    }
  },
  mounted() {

    // reset inserat local storage
    localStorage.inseratStep = 0;
    localStorage.inseratType = null;
    localStorage.inseratTitle = null;
    localStorage.inseratDescription = null;
    localStorage.inseratCategoryId = null;
    localStorage.inseratPrice = null;
    localStorage.inseratCurrency = null;
    localStorage.inseratIsGoodCause = null;
    localStorage.inseratDonationRecipient = null;
    localStorage.inseratDonationRecipientId = null;
    localStorage.persistedInserat = null;
    localStorage.persistedImage = null;


    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){

      // check for user permissions
      axios.get(process.env.VUE_APP_API_URL + '/star-permissions', {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {

            if (!response.data.status){
              this.$router.push({ path: '/login' })
            }

            this.finishedLoading = true;

          })
          .catch(error => {
            this.$router.push({ path: '/login' });
          });

    } else {
        // push to main
        this.$router.push({ path: '/login' })
    }

  },
  methods: {
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration
          })
      return toast.present();
    },
    nextStep(selection: number) {

        if (selection == 0){
            // show error
            this.openToast("Ungültige Auswahl.", 1000);
        } else if (selection >= 4){
            // show error
            this.openToast("Ungültige Auswahl.", 1000);
        } else {
            // save in localStorage

            if (selection == 1){
              localStorage.inseratType = "directbuy";
            }

            if (selection == 2){
              localStorage.inseratType = "auction";
            }

            if (selection == 3){
              localStorage.inseratType = "gift";
            }

            // go to next step
            localStorage.inseratStep = 1;
            this.$router.push({ path: '/inserat/create/step-1' })
        }
    }
  }
}
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 10%;
}

.halfMargins {
  margin-top: 5%;
}

</style>